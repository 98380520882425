.App {
  text-align: center;
}

.App-header {
  position: fixed;
  z-index: 1;
}

.App-content {
  position: relative;
  z-index: 0;
  padding-top: 150px;
}


body {
    display: flex;
    overflow-x: auto;
    padding-top: 10px;
    padding-left: 50px;
    padding-right: 50px;
}